"use client";

import { useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import mixpanel from "mixpanel-browser";

import StandaloneLogoWhiteSVG from "purple-rain/assets/standalone-logo-white.svg";
import MoneyGraphBarIncreaseSVG from "purple-rain/icons/money-graph-bar-increase.svg";
import InterfaceContentChartSVG from "purple-rain/icons/interface-content-chart.svg";

import { useScopedI18n } from "@/locales/client";
import { MIXPANEL_EVENTS, appendUTMParams } from "analytics";

export default function HomeClient() {
  const t = useScopedI18n("home");

  const lakeviewLink =
    process.env.NEXT_PUBLIC_LAKEVIEW_LOAN_SERVICING_AUDIENCE ||
    "https://lakeviewloanservicing.fastlanefi.com";
  const lakeviewLinkWithSessionId = appendUTMParams(lakeviewLink as string);

  useEffect(() => {
    mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
      module: "welcome_module",
    });

    mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
      module: "top_resources",
    });

    mixpanel.track(MIXPANEL_EVENTS.MODULE_SERVED, {
      module: "lakeview_module",
    });

    mixpanel.track_links("a.read-article", MIXPANEL_EVENTS.CLICK, {
      button: "article-content-link",
      referrer: document.referrer,
    });

    mixpanel.track_links("#visit-lakeview", MIXPANEL_EVENTS.CLICK, {
      button: "visit-lakeview-link",
      referrer: document.referrer,
    });
  }, []);

  return (
    <>
      <section className="text-white px-0 d-flex bg-gradient">
        <div className="container my-5 my-md-7">
          <div className="d-flex flex-column gap-4 gap-md-6 flex-lg-row-reverse align-items-center justify-content-md-between">
            <div className="flex-lg-grow-1">
              <Image
                src="/through-the-looking-glass.svg"
                width={330}
                height={217}
                alt="Through the looking glass"
                priority
              />
            </div>
            <div className="d-flex gap-3 flex-column flex-lg-grow-6">
              <h3 className="text-headline-03">{t("banner.heading")}</h3>
              <div className="fw-light text-body-02 text-md-body-01">
                {t("banner.sub_heading")}
              </div>
              <div className="d-flex flex-row gap-3">
                <Image
                  src={InterfaceContentChartSVG}
                  className="filter-white mt-1"
                  width={22}
                  height={22}
                  alt="Icon interface content chart"
                />
                <div className="text-body-02 text-md-body-01">
                  <span className="fw-bold">{t("banner.aim_1.summary")}</span>{" "}
                  <span className="fw-light">
                    {t("banner.aim_1.deep_dive")}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-row gap-3">
                <Image
                  src={MoneyGraphBarIncreaseSVG}
                  className="filter-white mt-1"
                  width={22}
                  height={22}
                  alt="Icon money graph bar increase"
                />
                <div className="text-body-02 text-md-body-01">
                  <span className="fw-bold">{t("banner.aim_2.summary")}</span>{" "}
                  <span className="fw-light">
                    {t("banner.aim_2.deep_dive")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-7">
        <div className="container">
          <h4 className="text-headline-05 text-md-headline-04">
            {t("resources.heading")}
          </h4>
          <div className="text-body-02 text-md-body-01 my-4">
            {t("resources.sub_heading")}
          </div>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3 g-md-4">
            <div className="col">
              <div className="card card-article gap-3 p-3 bg-primary-01">
                <Image
                  src="/home-improvements.jpg"
                  width={40}
                  height={36}
                  alt="Home improvements cover"
                  className="card-img"
                />
                <div className="card-body p-0">
                  <div className="mb-2 text-body-01 fw-bold">
                    {t("resources.articles.home_improvements.heading")}
                  </div>
                  <Link
                    className="read-article"
                    href="/resource-center/5-energy-efficient-home-improvement-projects-that-boost-value"
                  >
                    {t("resources.articles.link")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-white bg-primary-01 py-7">
        <div className="container">
          <div className="card bg-gradient-lakeview rounded-3 px-6 py-5">
            <Image
              src={StandaloneLogoWhiteSVG}
              width={170}
              alt="Lakeview cobranded logo"
            />
            <hr className="w-100 text-white text-opacity-100 my-4" />
            <h5 className="text-white">{t("lakeview.heading")}</h5>
            <div className="d-flex flex-column align-items-stretch flex-sm-row gap-4 gap-lg-7 text-white">
              <div
                className="text-body-02 text-md-body-01"
                dangerouslySetInnerHTML={{
                  __html: t("lakeview.content"),
                }}
              />

              <div className="mt-3">
                <Link
                  href={lakeviewLinkWithSessionId}
                  id="visit-lakeview"
                  passHref={true}
                  target="_blank"
                  className="text-nowrap text-white text-decoration-none rounded-2 py-2 px-4 w-100 d-flex justify-content-center d-sm-block btn-outline-light border border-2 border-light"
                >
                  {t("lakeview.cta")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
